@use 'sass:math';

@keyframes slide {
	0% { transform: translateX(-100%);}
	100% { transform: translateX(100%);}
}

.calendar-year {
    position: relative;

    &__months {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        gap: 7px;
        margin: 40px 0;

        @include tablet() {
            grid-template-columns: repeat(12, minmax(0, 1fr));
            gap: vwmax(10px);
            margin: 66px 0;
        }
    }

    &__month {
        border: 1px solid black;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include tablet() {
            flex-direction: column;
            min-height: vwmax(804px);
            width: auto;
        }
    }

    &__label {
        font-family: var(--font-fgtas);
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        text-align: center;
        width: 89px;
        flex-shrink: 0;

        @include tablet() {
            font-size: vwmax(40px);
            width: auto;
        }
    }

    &__month {
        &-events {
            position: relative;
            width: 100%;

            @include tablet('max') {
                display: flex;
                align-items: center;
                justify-content: left;
                margin-left: -5%;
            }
        
        }

        &-magazines {
            order: 99;
            width: 98px;
            flex-shrink: 0;

            @include tablet() {
                width: 100%;
                margin-top: auto;
                min-height: vwmax(134px);
            }
        }
    }

    &__event {
        font-family: var(--font-fgtas);
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        text-align: left;
        text-transform: uppercase;
        position: relative;
        flex-shrink: 0;

        &__bg {
            position: absolute;
            max-width: none;
            width: 98px;
            left: -18px;
            top: -17px;
            z-index: -1;

            img {
                width: 100%;
            }

            @include tablet() {
                width: vwmax(98px);
                left: vwmax(-18px);
                top: vwmax(-15px);
            }
        }

        a {
            text-decoration: none;
            color: inherit;
            font-weight: inherit;

            &:hover {
                text-decoration: none;
                color: inherit;
            }
        }

        a.label,
        span.label {
            position: relative;
        }

        span.label,
        span.label-title,
        span.label-date {
            display: block;
        }

        &--regular {
            max-width: 75px;

            span.label-date {
                margin-top: 4px;
            }

            @include tablet('max') {


                @for $i from 0 through 30 {
                    &[data-progress="#{$i}"] {
                        margin-left: math.div($i, 30) * 55%;
                    }

                    &[data-progress] + &[data-progress] {
                        margin-left: math.div($i, 30) * 10%;
                    }
                }
            }

            @include tablet() {
                position: absolute;
                left: vwmax(6px);
                right: vwmax(6px);
                top: 0;
                font-size: vwmax(14px);
                max-width: none;

                span.label-date {
                    margin-top: vwmax(4px);
                }

                @for $i from 0 through 30 {
                    &[data-progress="#{$i}"] {
                        top: vwmax(math.div($i, 30) * 580px);
                    }
                }
            }
        }

        &--magazine {
            display: flex;
            align-items: center;
            gap: 7px;
            font-weight: 500;
            font-size: 12px;

            figure {
                img {
                    max-width: 28px;
                }
            }

            @include tablet() {
                display: block;
                font-size: vwmax(11.5px);
                margin-left: vwmax(6px);
                margin-right: vwmax(2px);
                margin-bottom: vwmax(6px);

                figure {
                    margin-bottom: 12px;
    
                    img {
                        margin-left: 0;
                        max-width: vwmax(69px);
                        max-height: vwmax(69px);
                    }
                }
            }
        }
    }

    &__overlay {
        position: fixed;
        background-color: black;
        top: var(--header-height);
        left: 0;
        right: 0;
        height: calc(100vh - var(--header-height));
        color: white;
        box-sizing: border-box;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 0.2s, visibility 0.2s;
        overflow: hidden;

        @include tablet() {
            position: absolute;
            top: vwmax(252px);
            left: -100px;
            right: -100px;
            margin-left: auto;
            margin-right: auto;
            width: 1240px;
            max-width: 100vw;
            min-height: vwmax(360px);
        }

        &:after {
            content:'';
            top: 0;
            transform: translateX(100%);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            pointer-events: none;
            animation: slide 1s infinite;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.2s, visibility 0.2s;
            background: linear-gradient(100deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 25%,rgba(255,255,255,0.175) 50%,rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%);
        }

        &.show {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

        &.loading {
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }

        &-close {
            position: absolute;
            width: 32px;
            height: 32px;
            text-indent: 100px;
            white-space: nowrap;
            overflow: hidden;
            right: 13.5px;
            top: 13.5px;
            border: 0;
            padding: 0;
            margin: 0;
            cursor: pointer;
            background-color: transparent;
            background-image: url('../../images/calendar-overlay-close-button.svg');
            background-repeat: no-repeat;
            background-position: center;

            @include tablet() {
                right: 15.5px;
                top: 15.5px;
            }
        }

        &-content {
            margin: 22px;
            margin-top: 54px;

            @include tablet() {
                margin: 48px;
                margin-top: 59px;
            }
        }
    }
}

.event-overlay {
    font-family: var(--font-fgtas);
    font-size: 15.5px;
    font-weight: 400;
    line-height: 1.2903;
    text-align: left;

    @include tablet() {
        font-size: 22px;
        line-height: 1.2727;
    }

    &__title {
        font-family: var(--font-fgtas);
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-align: left;
        color: white;
        margin: 0;
        max-width: none;
        padding: 0;
        text-transform: uppercase;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        margin-top: 5px;
        margin-bottom: 10px;

        @include tablet() {
            text-align: center;
            justify-self: center;
            font-size: 40px;
            grid-column: 2 / 3;
            grid-row: 1 / span 2;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &__register-link {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        font-family: var(--font-fgtas);
        font-size: 15px;
        font-weight: 700;
        line-height: 1.2;
        width: 133px;
        height: 32px;
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        justify-self: flex-end;
        grid-column: 2 / 3;
        grid-row: 1 / span 3;
        margin-right: 0;
        max-width: 100%;

        &:hover {
            color: white;
            text-decoration: none;
        }

        @include tablet() {
            grid-column: 3 / 4;
            grid-row: 1 / span 2;
            font-size: 22px;
            width: 192px;
            height: 48px;
            margin-right: 9px;
        }
    }

    &__header {
        display: grid;
        gap: 0.65em;
        grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
        margin-bottom: 30px;

        @include tablet() {
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
            margin-bottom: 46px;
            gap: 0.7em;
        }
    }

    &__date {
        margin: 0;
        font-family: var(--font-fgtas);
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        grid-column: 1 / 2;
        grid-row: 2 / 3;

        @include tablet() {
            font-size: 40px;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
    }

    &__location {
        margin: 0;
        font-family: var(--font-fgtas);
        font-size: 15px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        @include tablet() {
            font-size: 22px;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }
    }

    &__content {
        max-height: calc(100vh - var(--header-height) - 195px);
        overflow-y: auto;
        margin-right: -23px;
        padding-right: 50px;

        #main & [class^="ce_"] {
            max-width: none;
        }

        @include tablet() {
            max-height: 473px;
            margin-right: -23px;
            padding-right: 80px;
        }
    }
}
