#main .ce_group_start {
    &.width-full {
        width: calc(100% + (var(--outer-padding) * 2));

        & > * {
            max-width: none;

            &.width-full {
                margin-left: 0;
                margin-right: 0;
            }

            &.ce_swiperStart {
                min-width: 0;
            }
        }
    }

    &.start-latest-slider-container {
        @include tablet() {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}
