.mod_banner.banner--swiper {
    .swiper-container {
        position: relative;

        &:after {
            content: 'Anzeige';
            display: block;
            font-family: var(--standard);
            font-weight: 500;
            font-size: 8.5px;
            text-transform: uppercase;
            right: 6px;
            bottom: 0.5em;
            position: absolute;
            z-index: 2;
            color: black;
            text-shadow: 0 0 10px hsla(0 , 0%, 100%, 0.7);
            pointer-events: none;

            @include desktop() {
                font-size: 2.4084vh;
                right: 2.4084vh;
            }
        }
    } 

    .swiper-slide {
        pointer-events: all;
    }
}

#main .mod_banner.banner--subhead {
    max-width: none;
    margin: 1em calc(var(--outer-padding) * -1);
    display: flex;
    justify-content: center;

    .swiper-container {
        max-width: 925px;

        &:after {
            @include desktop() {
                font-size: 14px;
                right: 9px;
            }
        }
    }
}

#main .mod_banner.banner--sidebar-start-desktop {
    display: none;
    flex-shrink: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: max-content;

    @include desktop() {
        display: block;
    }

    img {
        width: 100%;
    }

    .swiper-container {
        aspect-ratio: 0.444898;
        height: calc(100vh - var(--header-height));
        min-height: 500px;
    }
}

#main .mod_banner.banner--sidebar-start-mobile {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-color: white;

    @include desktop() {
        display: none;
    }

    img {
        width: 100%;
    }
}

.mod_banner.banner--sidebar-article-desktop {
    display: none;
    flex-shrink: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: max-content;
    position: relative;

    @include desktop() {
        display: block;
    }

    img {
        width: 100%;
    }

    .swiper-container {
        aspect-ratio: 0.444898;
        height: calc(100vh - var(--header-height));
        min-height: 500px;
    }
}

.mod_banner.banner--sidebar-article-mobile {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-color: white;

    @include desktop() {
        display: none;
    }

    img {
        width: 100%;
    }
}

#main .kia-ad,
#right .kia-ad {
    position: relative;
    background-color: #fff;

    &:after {
        content: 'Anzeige';
        display: block;
        font-family: var(--standard);
        font-weight: 500;
        font-size: 8.5px;
        text-transform: uppercase;
        right: 6px;
        bottom: 0.5em;
        position: absolute;
        z-index: 2;
        color: black;
        text-shadow: 0 0 10px hsla(0 , 0%, 100%, 0.7);
        pointer-events: none;

        @include desktop() {
            font-size: 2.4084vh;
            right: 2.4084vh;
        }
    }

    button {
        padding: 2px;
        display: flex;
        position: absolute;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        right: 4px;
        top: 4px;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.3);
        border-radius: 5px;
        overflow: hidden;
        color: rgba(0,0,0,0.7);
        border: 0;
        cursor: pointer;

        @include tablet() {
            display: none;
        }
    }

    iframe {
        display: block;
        border: none;
        width: 100%;
        height: 100%;
    }
}

#main .kia-ad {
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	z-index: 5;
	width: 100%;
	height: 290px;
    display: block;
    flex-shrink: 0;

    @include tablet() {
        background-color: black;
        position: relative;
        aspect-ratio: .444898;
        height: calc(100vh - var(--header-height));
        min-height: 500px;
        max-width: 400px !important;
    }
}

#right .kia-ad {
    @include tablet('max') {
        position: fixed;
        top: auto !important;
        left: 0;
        bottom: 0;
        z-index: 5;
        width: 100%;
        aspect-ratio: 1.26;
        z-index:100000000000;
    }

    @include tablet() {
        position: relative;
        aspect-ratio: 0.444898;
        height: calc(100vh - var(--header-height));
        min-height: 500px;
        max-width: 400px !important;
    }
}

#pre-footer{
    .kia-ad {
        @include tablet(){
        display: none;
        }
    }
}
