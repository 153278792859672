#container:has(#right) {
    @include desktop() {
        display: flex;

        #main {
            flex-grow: 1;
            box-sizing: border-box;
        }
    }
}

#wrapper:has(.banner--sidebar-article-mobile) {
    #footer {
        margin-bottom: 32.3vw;

        @include desktop() {
            margin-bottom: 0;
        }
    }
}
