/* global settings */
@import './settings/_layout.scss';

/* functions and mixins */
@import './functions/_breakpoints.scss'; @import './functions/_grid.scss'; @import './functions/_mixins.scss'; @import './functions/_styles.scss';

/* base styles settings */
@import './base/_basic.scss'; @import './base/_fonts.scss';

/* styles of various components */
;

/* section styles */
@import './sections/_container.scss';

/* module styles */
@import './modules/_mod_banner.scss'; @import './modules/_mod_calendar_year.scss';

/* element styles */
@import './elements/_ce_group_start.scss';
